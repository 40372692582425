<template>
  <div>
    <div v-if="editable&&isWriting" class="alert alert-info alert-dismissible fade show cursor-pointer" 
      style="margin-bottom:13px !important; padding: 10px 3px !important" role="alert"
      v-on:click="setPermitData">
      <span class="q-pl-sm q-pr-md">
        <q-icon name="o_emoji_objects" size="sm" />
      </span>
      <q-chip dense color="teal" text-color="white">
        발급
      </q-chip>
      정보를 허가서에서 가져오기
    </div>
    <c-card title="작업 위험성평가">
      <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-field
              :disabled="!isWriting"
              :editable="editable"
              :data="workPermit"
              :plantCd="workPermit.plantCd"
              :vendorCd="workPermit.vendorCd"
              :changeItem="changeItem"
              :unification="true"
              userName=""
              deptValue="assessWriteDeptCd"
              type="dept_user"
              label="발급"
              name="assessWriteUserId"
              v-model="workPermit.assessWriteUserId">
            </c-field>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-field
              :disabled="!isWriting"
              :editable="editable"
              :data="workPermit"
              :plantCd="workPermit.plantCd"
              :vendorCd="workPermit.vendorCd"
              :changeItem="changeItem"
              userName=""
              deptValue="assessApprovalDeptCd"
              type="dept_user"
              label="승인"
              name="assessApprovalUserId"
              v-model="workPermit.assessApprovalUserId">
            </c-field>
          </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="안전조치사항"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="workPermit.assessments"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&isWriting"
      selection="multiple"
      rowKey="sopWorkSopAssessmentId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&isWriting" label="추가" icon="add" :showLoading="false" @btnClicked="add" />
          <c-btn v-if="editable&&isWriting" label="제외" icon="remove" :showLoading="false" @btnClicked="remove" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'swp-assessment',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 공사현장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공종
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    changeItem: {
      type: Object,
      default: function() {
        return {
          plant: '',
          process: '',
          vendor: '',
        }
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업단계',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '잠재위험요소',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'safetyActionMeasures',
            field: 'safetyActionMeasures',
            label: '안전조치사항',
            align: 'left',
            type: 'text',
            value: '',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'actionEducationFlag',
            field: 'actionEducationFlag',
            label: '조치/교육완료여부',
            align: 'center',
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
        height: '400px'
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    add() {
      if (!this.workPermit.assessments) this.workPermit.assessments = [];
      this.workPermit.assessments.push({
        sopWorkSopAssessmentId: uid(),  // 작업허가서_작업위험성평가_일련번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        jobStepName: '',  // 작업단계 명
        riskHazardName: '',  // 유해위험요인명
        safetyActionMeasures: '',  // 안전조치사항
        actionEducationFlag: 'N',  // 조치/교육완료여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      });
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workPermit.deleteAssessments) this.workPermit.deleteAssessments = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workPermit.deleteAssessments, { sopWorkSopAssessmentId: item.sopWorkSopAssessmentId }) === -1) {
            this.workPermit.deleteAssessments.push(item)
          }
          this.workPermit.assessments = this.$_.reject(this.workPermit.assessments, item);
        });
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    setPermitData() {
      this.workPermit.assessWriteDeptCd = this.$_.clone(this.workPermit.issuedDeptCd)
      this.workPermit.assessWriteUserId = this.$_.clone(this.workPermit.issuedUserId)
      this.workPermit.assessApprovalDeptCd = this.$_.clone(this.workPermit.approvalDeptCd)
      this.workPermit.assessApprovalUserId = this.$_.clone(this.workPermit.approvalUserId)
    },
  }
};
</script>
